import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import BasicLayout from "../components/layouts/Basic"
import styled from "styled-components"

const Article = styled.article`
  p {
    margin-bottom: 20px;
  }

  ol,
  ul {
    margin-bottom: 30px;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const Date = styled.span`
  display: block;
  margin-bottom: 20px;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.pink};
`

const BlogTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <BasicLayout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.seoDescription}
      />
      <Article>
        <Title>{post.frontmatter.title}</Title>
        <Date>{post.frontmatter.date}</Date>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Article>
    </BasicLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        seoDescription
        date(formatString: "DD MMMM YYYY")
      }
    }
  }
`
export default BlogTemplate
